.contact-page {
    ul {
        padding-left: 2.5em;
        display: inline-block;
        li {
            font-size: 1.4em;
            margin: 0 0 1em;
            vertical-align: middle;
            a, i {
                transition: all 200ms ease;
                margin: 0; }
            i {
                text-shadow: 0 1px 0 #fff;
                vertical-align: middle;
                font-size: 1.4em;
                margin-right: .5em;
                color: #272d2d; }
            &:hover {
                i {
                    font-size: 1.6em; }
                i, a {
                    color: #ef5253; } } } } }

@media screen and (orientation:portrait) {
    .contact-page {
        ul {
            padding: 0;
            width: 100%;
            // justify-content: space-evenly
            // align-content: center
            position: relative;
            li {
                display: inline-block;
                width: 30vw;
                height: 30vw;
                padding: 0;
                position: relative;
                font-size: .7em;
                margin: 0;
                a {
                    width: 100%;
                    height: 100%; }
                i {
                    display: block;
                    font-size: 5em;
                    margin: 0;
                    margin-bottom: .2em; }
                &:hover {
                    // top: -1.2em
                    i {
                        margin-bottom: .03em;
                        font-size: 5.5em; } } } } } }
