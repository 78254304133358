.about-page {
    p {
        position: relative;
        line-height: 1.8em;
        text-align: justify;
        pointer-events: initial;
        a, span {
            user-select: initial;
            padding: 0;
            margin: 0;
            pointer-events: initial; }
        .red {
            color: #ef5253; }
        .underline {
            text-decoration: underline;
            text-decoration-color: #ef5253;
            text-underline-position: under;
            // &:hover
 }            //     text-decoration-style: wavy;
        a {
            transition: all 500ms ease;
            &:hover {
                background-color: #ef5253;
                color: #fff; } }
        // &::before
        //     z-index: -1
        //     content: ''
        //     // background-color: rgba(#fff, 0.85)
        //     background-color: red
        //     position: absolute
        //     width: 100vw
        //     height: 100%
        //     right: -25%
 } }        //     top: 0

@media screen and (max-width: 1024px) {
    // .about-page
    //     p
    //         &::before
    //             z-index: -1
    //             content: ''
    //             background-color: rgba(#fff, 0.95)
    //             // background-color: red
    //             position: absolute
    //             width: 100vw
    //             height: 100%
    //             right: -30px
 }    //             top: 0

// @media screen and (orientation:portrait)
//     .about-page
//         p
//          // background-color: rgba(#fff, .4)
