.typing {
  // text-transform: uppercase
  font-size: 2.4em;
  vertical-align: middle;
  span {
    display: inline-block;
    vertical-align: middle;
    text-transform: uppercase; }
  .red {
    font-size: 1.5em; }
  .typing-head {
    text-transform: initial;
    font-size: 0.9em;
    // font-size: 0.5em
    font-family: JosefinSans, Sunflower, 'Noto Sans JP', Roboto; } }

.home-page {
  .typing {
    span {
      vertical-align: middle;
      text-transform: initial; } } }

.cursor {
  margin-right: 10px;
  background: #484444;
  width: 2px;
  height: 5vh;
  font-family: JosefinSans, Sunflower, 'Noto Sans JP', Roboto;
  animation: blink 1s infinite; }

@media screen and (orientation:portrait) {
  .ja {
    .contact-page {
      .typing {
          line-height: .7em;
          .typing-head {
            font-size: .5em; }
          span {
            font-size: .9em; } } } }
  .typing {
    padding-top: 1em;
    padding-bottom: 1.3em;
    line-height: 1em;
    vertical-align: middle;
    .typing-head {
      font-size: 0.5em;
      display: block; }
    .red {
      font-size: 2em; } } }
