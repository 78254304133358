.ko {
    .home-page {
        height: 50%;
        padding: 60px 0 0;
        h1 {
            font-size: 5em;
            line-height: 1.5em; }
        p {
            padding: 40px 0 0; } } }

.ja {
    .home-page {
        h1 {
            letter-spacing: -20px;
            font-size: 4.5em;
            line-height: 1.2em; } }
    // .home-page
    //   h1
    //     font-size: 5em
    // h1
    //   font-size: 2.2em
    a, p {
      line-height: 1.5em; } }

.home-page {
    text-shadow: none;
    height: 60%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 20px 0 0;
    h1 {
        font-size: 6.5em;
        line-height: 1em;
        margin: 0;
        padding: 0;
        vertical-align: middle;
        span {
            vertical-align: middle;
            display: inline-block;
            padding: 10px 20px;
            margin-left: 20px;
            background-color: #282d2d;
            color: #fff; }
 }        // &:hover
    p, .cursor {
        user-select: none;
        // display: inline-block
        margin: 0;
        padding: 20px 0 0;
        font-size: 2em;
        text-shadow: 0 1px 0 #fff;
        .red {
            display: none; } } }

@media screen and (orientation:portrait) {
    .ko {
        .home-page {
            // padding: 20px 0 0
            h1 {
                display: {}
                font-size: 4.5em; } } }
    .ja {
        .home-page {
            height: 80%;
            h1 {
                // padding-top: 20px
                font-size: 4em; }
            p {
                font-size: 1.3em; } } }
    .home-page {
        height: 90%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        // height: 60vh
        padding: .2em 0 0;
        h1 {
            span {
                margin: 0; } }
        p {
            font-size: 1.6em; } } }
