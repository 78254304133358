@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.11.2/css/all.min.css');
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.11.2/css/brands.min.css');

@import url('https://fonts.googleapis.com/css?family=Noto+Sans+JP:200|Sawarabi+Mincho|East+Sea+Dokdo|Sunflower:300&display=swap');

/** Japanese fonts */
// @font-face
//   font-family: 'Guzuri'
//   src: local('Guzuri'), url(./fonts/guzuri/guzuri.ttf) format('ttf')
/****************/

/** Korean fonts */
@font-face {
  font-family: 'UhBeeRice';
  src: local('UhBeeRice'), url(./fonts/uhbeerice/UhBeeRice.eot);
  font-display: swap; }
@font-face {
  font-family: 'UhBeeRice';
  src: local('UhBeeRice'), url(./fonts/uhbeerice/UhBeeRice.woff) format('woff');
  font-display: swap; }
/****************/

/** Alphabet fonts */
@font-face {
  font-family: 'JosefinSans';
  src: local('JosefinSans'), url(./fonts/josefinsans/JosefinSans-Regular.ttf) format('truetype');
  font-display: swap; }
@font-face {
  font-family: 'AmaticSC';
  src: local('AmaticSC'), url(./fonts/amaticsc/AmaticSC-Regular.ttf) format('truetype');
  font-display: swap; }
/****************/

@import 'animation';

::-webkit-scrollbar {
  margin: 1px;
  width: 10px; }

::-webkit-scrollbar-thumb {
  background-color: rgba(#605d53, .5); }

::-moz-scrollbar {
  margin: 1px;
  width: 10px; }

::-moz-scrollbar-thumb {
  background-color: rgba(#605d53, .5); }

::-o-scrollbar {
  margin: 1px;
  width: 10px; }

::-o-scrollbar-thumb {
  background-color: rgba(#605d53, .5); }

* {
  user-select: none; }
.jp {
  letter-spacing: -2px; }
p {
  user-select: text;
  white-space: pre-line; }

#root {
  height: 100%;
  min-height: 100%; }

html {
  height: 100%;
  min-height: 100%; }

body {
  font-family: JosefinSans, Sunflower, 'Noto Sans JP', Roboto;
  color: #434444;
  position: relative;
  height: 100%;
  min-height: 100%;
  margin: 0;
  // font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

svg {
  overflow: inherit; }

a {
  display: inline-block;
  list-style: none;
  text-decoration: none;
  color: initial;
  margin-right: 1em;
  &.active {
      color: #ef5253; }
  &:hover {
      color: #ef5253; } }

h1 {
  font-size: 3em;
  font-family: AmaticSC, UhBeeRice, 'Sawarabi Mincho', Helvetica, sans-serif;
  color: #282d2d;
  margin: 0;
  padding: 0 0 30px;
  vertical-align: middle;
  .red {
      color: #ef5253; }
 }  // &:hover

ul {
  padding: 0;
  margin: 0;
  li {
    list-style: none; } }

@media screen and (orientation:portrait) {
  /* Portrait styles */
  html {
    text-align: center;
    font-size: 2em; }

  h1 {
    font-size: 10vw; } }

/* Landscape */
/* @media screen and (orientation:landscape) */
  /* Landscape styles */
