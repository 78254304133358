nav {
    height: 53px;
    padding: 0 0 20px;
    z-index: 8;
    opacity: 0;
    animation: fadingIn 1000ms ease-out 2s 1 normal forwards;
    div {
        user-select: none;
        border-color: #fff;
        border-top: 8px solid trasnparent;
        line-height: 1em;
        transition: all 100ms ease-out;
        // min-width: 50px
        display: inline-block;
        margin: 0;
        padding: 15px 14px;
        height: 13px;
        text-shadow: none;
        &.active,
        &:hover {
            border-top: 8px solid #ef5253;
            color: #ef5253;
            cursor: pointer;
            // font-weight: bold
 }            // background-color: #ef5253
        &.active {
            &:hover {
                cursor: default; } } } }

@media screen and (min-height: 560px) {
    nav {
        padding: 40px 0 20px; } }

@media screen and (orientation:portrait) {
    .ja {
        nav {
            font-size: 0.8em; } }
    nav {
        // padding-top: 20px
        display: flex;
        flex-direction: row;
        justify-content: center;

        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        padding: 0;
        height: initial;
        div {
            // height: 15px
            color: #fff;
            border: 0;
            // border-bottom: 8px solid trasnparent
            // text-shadow: 0 .4px 0 rgba(#282d2d, .3)
            // font-weight: bold
            &.active,
            &:hover {
                border: 0;
                color: #fff;
                background-color: #ef5253; } } } }
