.work-page {
  .tab-buttons {
    padding-bottom: 20px;
    li {
      height: 30px;
      display: inline-block;
      transition: border 200ms ease;
      pointer-events: initial;
      text-shadow: none;
      padding: 14px 15px 6px;
      &.active {
        background-color: #ef5253;
        color: #fff; }
      &:hover {
        cursor: pointer;
        background-color: #ef5253;
        color: #fff; } } }
  .work-item {
    background-color: rgba(#fff, 0.85);
    padding: 20px;
    width: calc(100% - 40px);
    text-align: left;
    margin-bottom: 2em;
    p {
      text-align: justify;
      line-height: 1.8em; } }
  .tooltip {
      display: none;
      width: 200px;
      height: 90px;
      background-color: red; }

  svg {
    transition: height 500ms ease;
    .bars {
      rect {
        pointer-events: initial;
        transition: fill 1000ms ease;
        fill: #ef5253;
 } }        // fill: #f0dc3f
    .texts, .titles {
      text {
        text-shadow: none;
        fill: #fff;
        font-size: .7em; } }
    .y-axis {
      .domain {
        display: none; }
      .tick {
        text {
          font-size: 2em; }
        line {
          display: none; } } } } }

@media screen and (orientation:portrait) {
  .work-page {
    .tab-buttons {
      margin: auto;
      width: 40vw;
      display: flex;
      flex-direction: row;
      align-content: center;
      justify-content: space-between;
      li {
        border-bottom: 5px solid transparent;
        &.active {
          background-color: initial;
          border-bottom: 5px solid #ef5253;
          color: #ef5253; }
        &:hover {
          cursor: pointer;
          background-color: initial;
          border-bottom: 5px solid #ef5253;
          color: #ef5253; } } }

    svg {
      .titles {
        font-size: 1.7em;
        font-weight: bold; } } } }
