@keyframes spin {
  100% {
    transform: rotate(360deg); } }

@keyframes gradiantAnimTopBot {
  0% {
    background-position: 50% 0%; }
  50% {
    background-position: 50% 100%; }
  100% {
    background-position: 50% 0%; } }

@keyframes gradiantAnimSide {
  0% {
    background-position: 79% 0%; }
  50% {
    background-position: 22% 100%; }
  100% {
    background-position: 79% 0%; } }

@keyframes blink {
    to {
        opacity: .0; } }

@keyframes rotate360 {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

@keyframes flick {
  0% {
    transform: translateY(0px); }
  14% {
    transform: translateY(0px); }
  15% {
    transform: translateY(20px); }
  17% {
    transform: translateY(0px); }
  39% {
    transform: translateY(0px); }
  40% {
    transform: translateY(20px); }
  42% {
    transform: translateY(0px); }
  74% {
    transform: translateY(0px); }
  75% {
    transform: translateY(20px); }
  78% {
    transform: translateY(0px); }
  94% {
    transform: translateY(0px); }
  95% {
    transform: translateY(20px); }
  96% {
    transform: translateY(0px); }
  98% {
    transform: translateY(20px); }
  100% {
    transform: translateY(0px); } }

@keyframes rotLeftEyeAnim {
  0% {
    transform: translate(0px, 0px); }
  5% {
    transform: translateY(2px); }
  50% {
    transform: translate(2px, -1px); }
  70% {
    transform: translate(4px, -1px); }
  75% {
    transform: translate(2px, 2px); }
  95% {
    transform: translateY(0.5px); }
  100% {
    transform: translate(0px, 0px); } }

@keyframes rotRightEyeAnim {
  0% {
    transform: translate(0,0); }
  14% {
    transform: translate(0,0); }
  15% {
    transform: translateX(2px); }
  17% {
    transform: translateX(0px); }
  20% {
    transform: translateX(0px); }
  25% {
    transform: translate(-2px, -1.5px); }
  25% {
    transform: translate(2.5px, -1px); }
  60% {
    transform: translate(2.5px, -1px); }
  62% {
    transform: translateX(3px); }
  64% {
    transform: translateX(3px); }
  66% {
    transform: translateX(1.5px); }
  75% {
    transform: translateX(1.5px); }
  89% {
    transform: translateX(-3px); }
  90% {
    transform: translateX(-3px); }
  92% {
    transform: translateX(2px); }
  100% {
    transform: translateX(0, 0); }
  100% {
    transform: translate(0,0); } }

@keyframes rotLeftEyeAnimColor {
  0% {
    fill: #F79838; }
  50% {
    fill: #F79838; }
  55% {
    fill: #fdfb1d; }
  58% {
    fill: #F79838; }
  75% {
    fill: #bf5218; }
  76% {
    fill: #bdb410; }
  79% {
    fill: #bf5218; }
  85% {
    fill: #F79838; }
  86% {
    fill: #fdfb1d; }
  89% {
    fill: #F79838; }
  100% {
    fill: #F79838; } }

@keyframes blinkColor {
  from {
    fill: #fcc792; }
  to {
    fill: #92593b; } }

@keyframes fadingIn {
  0% {
    opacity: 0; }
  89% {
    opacity: 0; }
  90% {
    opacity: 1; }
  100% {
    opacity: 1; } }

@keyframes fadingOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }
