@import url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.11.2/css/all.min.css);
@import url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.11.2/css/brands.min.css);
@import url(https://fonts.googleapis.com/css?family=Noto+Sans+JP:200|Sawarabi+Mincho|East+Sea+Dokdo|Sunflower:300&display=swap);
/** Japanese fonts */
/****************/
/** Korean fonts */
@font-face {
  font-family: 'UhBeeRice';
  src: local("UhBeeRice"), url(/static/media/UhBeeRice.2449fe35.eot);
  font-display: swap; }

@font-face {
  font-family: 'UhBeeRice';
  src: local("UhBeeRice"), url(/static/media/UhBeeRice.ccddd70e.woff) format("woff");
  font-display: swap; }

/****************/
/** Alphabet fonts */
@font-face {
  font-family: 'JosefinSans';
  src: local("JosefinSans"), url(/static/media/JosefinSans-Regular.673965c5.ttf) format("truetype");
  font-display: swap; }

@font-face {
  font-family: 'AmaticSC';
  src: local("AmaticSC"), url(/static/media/AmaticSC-Regular.bc94a100.ttf) format("truetype");
  font-display: swap; }

/****************/
@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }
@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@-webkit-keyframes gradiantAnimTopBot {
  0% {
    background-position: 50% 0%; }
  50% {
    background-position: 50% 100%; }
  100% {
    background-position: 50% 0%; } }

@keyframes gradiantAnimTopBot {
  0% {
    background-position: 50% 0%; }
  50% {
    background-position: 50% 100%; }
  100% {
    background-position: 50% 0%; } }

@-webkit-keyframes gradiantAnimSide {
  0% {
    background-position: 79% 0%; }
  50% {
    background-position: 22% 100%; }
  100% {
    background-position: 79% 0%; } }

@keyframes gradiantAnimSide {
  0% {
    background-position: 79% 0%; }
  50% {
    background-position: 22% 100%; }
  100% {
    background-position: 79% 0%; } }

@-webkit-keyframes blink {
  to {
    opacity: .0; } }

@keyframes blink {
  to {
    opacity: .0; } }

@-webkit-keyframes rotate360 {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes rotate360 {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@-webkit-keyframes flick {
  0% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px); }
  14% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px); }
  15% {
    -webkit-transform: translateY(20px);
            transform: translateY(20px); }
  17% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px); }
  39% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px); }
  40% {
    -webkit-transform: translateY(20px);
            transform: translateY(20px); }
  42% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px); }
  74% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px); }
  75% {
    -webkit-transform: translateY(20px);
            transform: translateY(20px); }
  78% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px); }
  94% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px); }
  95% {
    -webkit-transform: translateY(20px);
            transform: translateY(20px); }
  96% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px); }
  98% {
    -webkit-transform: translateY(20px);
            transform: translateY(20px); }
  100% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px); } }

@keyframes flick {
  0% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px); }
  14% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px); }
  15% {
    -webkit-transform: translateY(20px);
            transform: translateY(20px); }
  17% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px); }
  39% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px); }
  40% {
    -webkit-transform: translateY(20px);
            transform: translateY(20px); }
  42% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px); }
  74% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px); }
  75% {
    -webkit-transform: translateY(20px);
            transform: translateY(20px); }
  78% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px); }
  94% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px); }
  95% {
    -webkit-transform: translateY(20px);
            transform: translateY(20px); }
  96% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px); }
  98% {
    -webkit-transform: translateY(20px);
            transform: translateY(20px); }
  100% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px); } }

@-webkit-keyframes rotLeftEyeAnim {
  0% {
    -webkit-transform: translate(0px, 0px);
            transform: translate(0px, 0px); }
  5% {
    -webkit-transform: translateY(2px);
            transform: translateY(2px); }
  50% {
    -webkit-transform: translate(2px, -1px);
            transform: translate(2px, -1px); }
  70% {
    -webkit-transform: translate(4px, -1px);
            transform: translate(4px, -1px); }
  75% {
    -webkit-transform: translate(2px, 2px);
            transform: translate(2px, 2px); }
  95% {
    -webkit-transform: translateY(0.5px);
            transform: translateY(0.5px); }
  100% {
    -webkit-transform: translate(0px, 0px);
            transform: translate(0px, 0px); } }

@keyframes rotLeftEyeAnim {
  0% {
    -webkit-transform: translate(0px, 0px);
            transform: translate(0px, 0px); }
  5% {
    -webkit-transform: translateY(2px);
            transform: translateY(2px); }
  50% {
    -webkit-transform: translate(2px, -1px);
            transform: translate(2px, -1px); }
  70% {
    -webkit-transform: translate(4px, -1px);
            transform: translate(4px, -1px); }
  75% {
    -webkit-transform: translate(2px, 2px);
            transform: translate(2px, 2px); }
  95% {
    -webkit-transform: translateY(0.5px);
            transform: translateY(0.5px); }
  100% {
    -webkit-transform: translate(0px, 0px);
            transform: translate(0px, 0px); } }

@-webkit-keyframes rotRightEyeAnim {
  0% {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); }
  14% {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); }
  15% {
    -webkit-transform: translateX(2px);
            transform: translateX(2px); }
  17% {
    -webkit-transform: translateX(0px);
            transform: translateX(0px); }
  20% {
    -webkit-transform: translateX(0px);
            transform: translateX(0px); }
  25% {
    -webkit-transform: translate(-2px, -1.5px);
            transform: translate(-2px, -1.5px); }
  25% {
    -webkit-transform: translate(2.5px, -1px);
            transform: translate(2.5px, -1px); }
  60% {
    -webkit-transform: translate(2.5px, -1px);
            transform: translate(2.5px, -1px); }
  62% {
    -webkit-transform: translateX(3px);
            transform: translateX(3px); }
  64% {
    -webkit-transform: translateX(3px);
            transform: translateX(3px); }
  66% {
    -webkit-transform: translateX(1.5px);
            transform: translateX(1.5px); }
  75% {
    -webkit-transform: translateX(1.5px);
            transform: translateX(1.5px); }
  89% {
    -webkit-transform: translateX(-3px);
            transform: translateX(-3px); }
  90% {
    -webkit-transform: translateX(-3px);
            transform: translateX(-3px); }
  92% {
    -webkit-transform: translateX(2px);
            transform: translateX(2px); }
  100% {
    -webkit-transform: translateX(0, 0);
            transform: translateX(0, 0); }
  100% {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); } }

@keyframes rotRightEyeAnim {
  0% {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); }
  14% {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); }
  15% {
    -webkit-transform: translateX(2px);
            transform: translateX(2px); }
  17% {
    -webkit-transform: translateX(0px);
            transform: translateX(0px); }
  20% {
    -webkit-transform: translateX(0px);
            transform: translateX(0px); }
  25% {
    -webkit-transform: translate(-2px, -1.5px);
            transform: translate(-2px, -1.5px); }
  25% {
    -webkit-transform: translate(2.5px, -1px);
            transform: translate(2.5px, -1px); }
  60% {
    -webkit-transform: translate(2.5px, -1px);
            transform: translate(2.5px, -1px); }
  62% {
    -webkit-transform: translateX(3px);
            transform: translateX(3px); }
  64% {
    -webkit-transform: translateX(3px);
            transform: translateX(3px); }
  66% {
    -webkit-transform: translateX(1.5px);
            transform: translateX(1.5px); }
  75% {
    -webkit-transform: translateX(1.5px);
            transform: translateX(1.5px); }
  89% {
    -webkit-transform: translateX(-3px);
            transform: translateX(-3px); }
  90% {
    -webkit-transform: translateX(-3px);
            transform: translateX(-3px); }
  92% {
    -webkit-transform: translateX(2px);
            transform: translateX(2px); }
  100% {
    -webkit-transform: translateX(0, 0);
            transform: translateX(0, 0); }
  100% {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); } }

@-webkit-keyframes rotLeftEyeAnimColor {
  0% {
    fill: #F79838; }
  50% {
    fill: #F79838; }
  55% {
    fill: #fdfb1d; }
  58% {
    fill: #F79838; }
  75% {
    fill: #bf5218; }
  76% {
    fill: #bdb410; }
  79% {
    fill: #bf5218; }
  85% {
    fill: #F79838; }
  86% {
    fill: #fdfb1d; }
  89% {
    fill: #F79838; }
  100% {
    fill: #F79838; } }

@keyframes rotLeftEyeAnimColor {
  0% {
    fill: #F79838; }
  50% {
    fill: #F79838; }
  55% {
    fill: #fdfb1d; }
  58% {
    fill: #F79838; }
  75% {
    fill: #bf5218; }
  76% {
    fill: #bdb410; }
  79% {
    fill: #bf5218; }
  85% {
    fill: #F79838; }
  86% {
    fill: #fdfb1d; }
  89% {
    fill: #F79838; }
  100% {
    fill: #F79838; } }

@-webkit-keyframes blinkColor {
  from {
    fill: #fcc792; }
  to {
    fill: #92593b; } }

@keyframes blinkColor {
  from {
    fill: #fcc792; }
  to {
    fill: #92593b; } }

@-webkit-keyframes fadingIn {
  0% {
    opacity: 0; }
  89% {
    opacity: 0; }
  90% {
    opacity: 1; }
  100% {
    opacity: 1; } }

@keyframes fadingIn {
  0% {
    opacity: 0; }
  89% {
    opacity: 0; }
  90% {
    opacity: 1; }
  100% {
    opacity: 1; } }

@-webkit-keyframes fadingOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

@keyframes fadingOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

::-webkit-scrollbar {
  margin: 1px;
  width: 10px; }

::-webkit-scrollbar-thumb {
  background-color: rgba(96, 93, 83, 0.5); }

::-moz-scrollbar {
  margin: 1px;
  width: 10px; }

::-moz-scrollbar-thumb {
  background-color: rgba(96, 93, 83, 0.5); }

::-o-scrollbar {
  margin: 1px;
  width: 10px; }

::-o-scrollbar-thumb {
  background-color: rgba(96, 93, 83, 0.5); }

* {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }

.jp {
  letter-spacing: -2px; }

p {
  -webkit-user-select: text;
     -moz-user-select: text;
      -ms-user-select: text;
          user-select: text;
  white-space: pre-line; }

#root {
  height: 100%;
  min-height: 100%; }

html {
  height: 100%;
  min-height: 100%; }

body {
  font-family: JosefinSans, Sunflower, 'Noto Sans JP', Roboto;
  color: #434444;
  position: relative;
  height: 100%;
  min-height: 100%;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

svg {
  overflow: inherit; }

a {
  display: inline-block;
  list-style: none;
  text-decoration: none;
  color: #000;
  color: initial;
  margin-right: 1em; }
  a.active {
    color: #ef5253; }
  a:hover {
    color: #ef5253; }

h1 {
  font-size: 3em;
  font-family: AmaticSC, UhBeeRice, 'Sawarabi Mincho', Helvetica, sans-serif;
  color: #282d2d;
  margin: 0;
  padding: 0 0 30px;
  vertical-align: middle; }
  h1 .red {
    color: #ef5253; }

ul {
  padding: 0;
  margin: 0; }
  ul li {
    list-style: none; }

@media screen and (orientation: portrait) {
  /* Portrait styles */
  html {
    text-align: center;
    font-size: 2em; }
  h1 {
    font-size: 10vw; } }

/* Landscape */
/* @media screen and (orientation:landscape) */
/* Landscape styles */

.typing {
  font-size: 2.4em;
  vertical-align: middle; }
  .typing span {
    display: inline-block;
    vertical-align: middle;
    text-transform: uppercase; }
  .typing .red {
    font-size: 1.5em; }
  .typing .typing-head {
    text-transform: none;
    text-transform: initial;
    font-size: 0.9em;
    font-family: JosefinSans, Sunflower, 'Noto Sans JP', Roboto; }

.home-page .typing span {
  vertical-align: middle;
  text-transform: none;
  text-transform: initial; }

.cursor {
  margin-right: 10px;
  background: #484444;
  width: 2px;
  height: 5vh;
  font-family: JosefinSans, Sunflower, 'Noto Sans JP', Roboto;
  -webkit-animation: blink 1s infinite;
          animation: blink 1s infinite; }

@media screen and (orientation: portrait) {
  .ja .contact-page .typing {
    line-height: .7em; }
    .ja .contact-page .typing .typing-head {
      font-size: .5em; }
    .ja .contact-page .typing span {
      font-size: .9em; }
  .typing {
    padding-top: 1em;
    padding-bottom: 1.3em;
    line-height: 1em;
    vertical-align: middle; }
    .typing .typing-head {
      font-size: 0.5em;
      display: block; }
    .typing .red {
      font-size: 2em; } }

.ko .home-page {
  height: 50%;
  padding: 60px 0 0; }
  .ko .home-page h1 {
    font-size: 5em;
    line-height: 1.5em; }
  .ko .home-page p {
    padding: 40px 0 0; }

.ja .home-page h1 {
  letter-spacing: -20px;
  font-size: 4.5em;
  line-height: 1.2em; }

.ja a, .ja p {
  line-height: 1.5em; }

.home-page {
  text-shadow: none;
  height: 60%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px 0 0; }
  .home-page h1 {
    font-size: 6.5em;
    line-height: 1em;
    margin: 0;
    padding: 0;
    vertical-align: middle; }
    .home-page h1 span {
      vertical-align: middle;
      display: inline-block;
      padding: 10px 20px;
      margin-left: 20px;
      background-color: #282d2d;
      color: #fff; }
  .home-page p, .home-page .cursor {
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    margin: 0;
    padding: 20px 0 0;
    font-size: 2em;
    text-shadow: 0 1px 0 #fff; }
    .home-page p .red, .home-page .cursor .red {
      display: none; }

@media screen and (orientation: portrait) {
  .ko .home-page h1 {
    font-size: 4.5em; }
  .ja .home-page {
    height: 80%; }
    .ja .home-page h1 {
      font-size: 4em; }
    .ja .home-page p {
      font-size: 1.3em; }
  .home-page {
    height: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: .2em 0 0; }
    .home-page h1 span {
      margin: 0; }
    .home-page p {
      font-size: 1.6em; } }

.about-page p {
  position: relative;
  line-height: 1.8em;
  text-align: justify;
  pointer-events: initial; }
  .about-page p a, .about-page p span {
    -webkit-user-select: initial;
       -moz-user-select: initial;
        -ms-user-select: initial;
            user-select: initial;
    padding: 0;
    margin: 0;
    pointer-events: initial; }
  .about-page p .red {
    color: #ef5253; }
  .about-page p .underline {
    text-decoration: underline;
    -webkit-text-decoration-color: #ef5253;
            text-decoration-color: #ef5253;
    text-underline-position: under; }
  .about-page p a {
    -webkit-transition: all 500ms ease;
    transition: all 500ms ease; }
    .about-page p a:hover {
      background-color: #ef5253;
      color: #fff; }

.work-page .tab-buttons {
  padding-bottom: 20px; }
  .work-page .tab-buttons li {
    height: 30px;
    display: inline-block;
    -webkit-transition: border 200ms ease;
    transition: border 200ms ease;
    pointer-events: initial;
    text-shadow: none;
    padding: 14px 15px 6px; }
    .work-page .tab-buttons li.active {
      background-color: #ef5253;
      color: #fff; }
    .work-page .tab-buttons li:hover {
      cursor: pointer;
      background-color: #ef5253;
      color: #fff; }

.work-page .work-item {
  background-color: rgba(255, 255, 255, 0.85);
  padding: 20px;
  width: calc(100% - 40px);
  text-align: left;
  margin-bottom: 2em; }
  .work-page .work-item p {
    text-align: justify;
    line-height: 1.8em; }

.work-page .tooltip {
  display: none;
  width: 200px;
  height: 90px;
  background-color: red; }

.work-page svg {
  -webkit-transition: height 500ms ease;
  transition: height 500ms ease; }
  .work-page svg .bars rect {
    pointer-events: initial;
    -webkit-transition: fill 1000ms ease;
    transition: fill 1000ms ease;
    fill: #ef5253; }
  .work-page svg .texts text, .work-page svg .titles text {
    text-shadow: none;
    fill: #fff;
    font-size: .7em; }
  .work-page svg .y-axis .domain {
    display: none; }
  .work-page svg .y-axis .tick text {
    font-size: 2em; }
  .work-page svg .y-axis .tick line {
    display: none; }

@media screen and (orientation: portrait) {
  .work-page .tab-buttons {
    margin: auto;
    width: 40vw;
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: space-between; }
    .work-page .tab-buttons li {
      border-bottom: 5px solid transparent; }
      .work-page .tab-buttons li.active {
        background-color: transparent;
        background-color: initial;
        border-bottom: 5px solid #ef5253;
        color: #ef5253; }
      .work-page .tab-buttons li:hover {
        cursor: pointer;
        background-color: transparent;
        background-color: initial;
        border-bottom: 5px solid #ef5253;
        color: #ef5253; }
  .work-page svg .titles {
    font-size: 1.7em;
    font-weight: bold; } }

.contact-page ul {
  padding-left: 2.5em;
  display: inline-block; }
  .contact-page ul li {
    font-size: 1.4em;
    margin: 0 0 1em;
    vertical-align: middle; }
    .contact-page ul li a, .contact-page ul li i {
      -webkit-transition: all 200ms ease;
      transition: all 200ms ease;
      margin: 0; }
    .contact-page ul li i {
      text-shadow: 0 1px 0 #fff;
      vertical-align: middle;
      font-size: 1.4em;
      margin-right: .5em;
      color: #272d2d; }
    .contact-page ul li:hover i {
      font-size: 1.6em; }
    .contact-page ul li:hover i, .contact-page ul li:hover a {
      color: #ef5253; }

@media screen and (orientation: portrait) {
  .contact-page ul {
    padding: 0;
    width: 100%;
    position: relative; }
    .contact-page ul li {
      display: inline-block;
      width: 30vw;
      height: 30vw;
      padding: 0;
      position: relative;
      font-size: .7em;
      margin: 0; }
      .contact-page ul li a {
        width: 100%;
        height: 100%; }
      .contact-page ul li i {
        display: block;
        font-size: 5em;
        margin: 0;
        margin-bottom: .2em; }
      .contact-page ul li:hover i {
        margin-bottom: .03em;
        font-size: 5.5em; } }

nav {
  height: 53px;
  padding: 0 0 20px;
  z-index: 8;
  opacity: 0;
  -webkit-animation: fadingIn 1000ms ease-out 2s 1 normal forwards;
          animation: fadingIn 1000ms ease-out 2s 1 normal forwards; }
  nav div {
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    border-color: #fff;
    border-top: 8px solid trasnparent;
    line-height: 1em;
    -webkit-transition: all 100ms ease-out;
    transition: all 100ms ease-out;
    display: inline-block;
    margin: 0;
    padding: 15px 14px;
    height: 13px;
    text-shadow: none; }
    nav div.active, nav div:hover {
      border-top: 8px solid #ef5253;
      color: #ef5253;
      cursor: pointer; }
    nav div.active:hover {
      cursor: default; }

@media screen and (min-height: 560px) {
  nav {
    padding: 40px 0 20px; } }

@media screen and (orientation: portrait) {
  .ja nav {
    font-size: 0.8em; }
  nav {
    display: flex;
    flex-direction: row;
    justify-content: center;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 0;
    height: auto;
    height: initial; }
    nav div {
      color: #fff;
      border: 0; }
      nav div.active, nav div:hover {
        border: 0;
        color: #fff;
        background-color: #ef5253; } }

#Background {
  pointer-events: none;
  z-index: -1;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
  animation: spin 2000s linear infinite reverse; }

#RootPlanet {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  visibility: hidden;
  position: relative;
  z-index: 0; }

.svg-container {
  position: fixed;
  right: 10%;
  bottom: 0;
  width: 30em;
  height: 80vh;
  width: 80vh; }

#Cil:hover {
  cursor: pointer; }

#CilEyesClose {
  -webkit-animation: flick 6s 3s ease infinite;
          animation: flick 6s 3s ease infinite; }

#Rot:hover {
  cursor: pointer; }

#Rot #RotRightEye circle:last-child {
  /*fill:red;*/
  -webkit-animation-name: rotRightEyeAnim, blinkColor;
          animation-name: rotRightEyeAnim, blinkColor;
  -webkit-animation-duration: 5s, 10s;
          animation-duration: 5s, 10s;
  -webkit-animation-delay: 1s, 0;
          animation-delay: 1s, 0;
  -webkit-animation-timing-function: ease, ease;
          animation-timing-function: ease, ease;
  -webkit-animation-iteration-count: infinite, infinite;
          animation-iteration-count: infinite, infinite; }

#Rot #RotLeftEye circle:last-child {
  -webkit-animation: rotLeftEyeAnim 5s 3s ease infinite;
          animation: rotLeftEyeAnim 5s 3s ease infinite; }

#Rot #RotLeftEyeFill {
  fill: #F79838;
  -webkit-animation: rotLeftEyeAnimColor 5s ease infinite;
          animation: rotLeftEyeAnimColor 5s ease infinite; }

.shadow-char {
  visibility: hidden; }

.shadow1, .shadow2, .shadow3 {
  -webkit-transform-origin: center center;
          transform-origin: center center; }

.shadow1 {
  -webkit-animation: spin 500s linear infinite;
          animation: spin 500s linear infinite; }

.shadow2 {
  animation: spin 400s linear infinite reverse; }

.shadow3 {
  -webkit-animation: spin 2000s linear infinite;
          animation: spin 2000s linear infinite; }

@media screen and (min-width: 1024px) {
  .svg-container {
    right: 0; } }

@media screen and (orientation: portrait) {
  .svg-container {
    right: -50%;
    left: -50%;
    margin: auto; } }

.app {
  background: linear-gradient(325deg, #edf0f7, #ffffff, #ffffff);
  background-repeat: no-repeat;
  background-size: 600% 600%;
  -webkit-animation: gradiantAnimSide 10s ease infinite;
          animation: gradiantAnimSide 10s ease infinite;
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 100%;
  font-size: calc(10px + 2vmin);
  text-shadow: 0 1px 0 #ccc; }
  .app .wrapper {
    height: 100%;
    min-height: 100%;
    padding-left: 30px;
    padding-right: 30px;
    width: calc(100% - 60px);
    max-width: 1024px;
    margin: auto; }
  .app.about, .app.work {
    height: auto;
    height: initial; }
    .app.about .page, .app.work .page {
      padding-bottom: 45vh; }

.content {
  pointer-events: none;
  position: relative;
  opacity: 0;
  z-index: 2;
  width: 100%;
  height: calc(100% - 113px);
  -webkit-animation: fadingIn 1000ms ease-out 1.8s 1 normal forwards;
          animation: fadingIn 1000ms ease-out 1.8s 1 normal forwards; }
  .content a {
    pointer-events: initial; }

.flags {
  z-index: 10;
  position: absolute;
  top: 80px;
  right: 10%;
  width: 2.6em;
  height: 2.6em; }
  .flags g.inactive {
    display: none; }
  .flags g.active {
    display: inline;
    display: initial; }
  .flags .modal {
    -webkit-transition: all 100ms ease;
    transition: all 100ms ease;
    z-index: 10;
    color: #fff;
    background-color: rgba(40, 45, 45, 0.9);
    min-width: 85px;
    text-align: center;
    position: absolute;
    padding: 10px;
    left: -50px;
    top: 80px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    box-shadow: -1px 1px 1px rgba(255, 255, 255, 0.2); }
    .flags .modal::before {
      -webkit-user-select: none;
         -moz-user-select: none;
          -ms-user-select: none;
              user-select: none;
      bottom: 100%;
      left: 50%;
      border: solid transparent;
      content: " ";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      border-color: rgba(40, 45, 45, 0);
      border-bottom-color: rgba(40, 45, 45, 0.8);
      border-width: 11px;
      margin-left: 11px; }
    .flags .modal .modal-background {
      padding: 0;
      background-color: transparent;
      position: fixed;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      z-index: -1; }
      .flags .modal .modal-background:hover {
        background-color: transparent; }
    .flags .modal div {
      text-shadow: none;
      padding: 8px; }
      .flags .modal div.active:hover {
        cursor: default; }
      .flags .modal div:hover {
        cursor: pointer; }
      .flags .modal div:hover, .flags .modal div.active {
        color: #fff;
        background-color: #ef5253; }

#Satellite {
  scale: 0;
  visibility: hidden; }
  #Satellite:hover {
    cursor: pointer; }

#Frontground {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  pointer-events: none;
  z-index: 2;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
  -webkit-animation: spin 1000s linear infinite;
          animation: spin 1000s linear infinite;
  -webkit-filter: none;
          filter: none;
  width: 60%;
  height: 60%; }

.sat {
  -webkit-animation: spin 100s linear infinite;
          animation: spin 100s linear infinite; }

.copyright {
  height: 20px;
  max-width: 1024px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  -webkit-transition: opacity 200ms ease;
  transition: opacity 200ms ease;
  position: absolute;
  z-index: 2;
  bottom: 10px;
  left: 10%;
  right: 0;
  margin: auto;
  font-size: .75em;
  opacity: .2; }
  .copyright:hover {
    opacity: 1; }

@media screen and (orientation: portrait) {
  .app {
    background: -webkit-gradient(linear, left top, left bottom, from(#ffffff), color-stop(#ffffff), color-stop(#ffffff), color-stop(#ffffff), color-stop(#ffffff), to(#eaebef));
    background: linear-gradient(-180deg, #ffffff, #ffffff, #ffffff, #ffffff, #ffffff, #eaebef);
    background-repeat: no-repeat;
    background-size: 100% 400%;
    -webkit-animation: gradiantAnimTopBot 6s ease infinite;
            animation: gradiantAnimTopBot 6s ease infinite; }
  .copyright {
    color: #282d2d;
    opacity: .1;
    top: 5px;
    left: 0;
    bottom: auto;
    bottom: initial; }
    .copyright:hover {
      opacity: .4; }
  .flags {
    top: 6vh;
    right: 4vh; }
    .flags .modal {
      padding-top: 20%;
      vertical-align: middle;
      padding: 0;
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      justify-content: center;
      font-size: 2em; }
      .flags .modal div {
        min-height: 50px; }
      .flags .modal > * {
        vertical-align: middle; } }

