.app {
  background: linear-gradient(325deg, #edf0f7, #ffffff, #ffffff);
  // background: linear-gradient(325deg, #edf0f7, #ffffff, #edf0f7)
  background-repeat: no-repeat;
  // background-size: cover
  background-size: 600% 600%;
  animation: gradiantAnimSide 10s ease infinite;
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 100%;
  font-size: calc(10px + 2vmin);
  text-shadow: 0 1px 0 #ccc;
  .wrapper {
    height: 100%;
    min-height: 100%;
    padding-left: 30px;
    padding-right: 30px;
    width: calc(100% - 60px);
    max-width: 1024px;
    margin: auto; }
  &.about, &.work {
    height: initial;
    .page {
      padding-bottom: 45vh; } } }


.content {
  pointer-events: none;
  position: relative;
  opacity: 0;
  z-index: 2;
  width: 100%;
  height: calc(100% - 113px);
  // background: rgba(red, 0.5)
  animation: fadingIn 1000ms ease-out 1.8s 1 normal forwards;
  a {
    pointer-events: initial; } }

.flags {
  z-index: 10;
  position: absolute;
  top: 80px;
  right: 10%;
  width: 2.6em;
  height: 2.6em;
  g {
    &.inactive {
      display: none; }
    &.active {
      display: initial; } }
  .modal {
    transition: all 100ms ease;
    z-index: 10;
    color: #fff;
    background-color: rgba(#282d2d, .9);
    min-width: 85px;
    text-align: center;
    position: absolute;
    padding: 10px;
    left: -50px;
    top: 80px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    box-shadow: -1px 1px 1px rgba(#fff, .2);
    &::before {
      user-select: none;
      bottom: 100%;
      left: 50%;
      border: solid transparent;
      content: " ";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      border-color: rgba(#282d2d, 0);
      border-bottom-color: rgba(#282d2d, .8);
      border-width: 11px;
      margin-left: 11px; }

    .modal-background {
      padding: 0;
      background-color: transparent;
      position: fixed;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      z-index: -1;
      &:hover {
        background-color: transparent; } }
    div {
      // min-height: 40px
      text-shadow: none;
      padding: 8px;
      &.active {
        &:hover {
          cursor: default; } }
      &:hover {
        cursor: pointer; }
      &:hover,
      &.active {
        color: #fff;
        background-color: #ef5253; } } } }

#Satellite {
  scale: 0;
  visibility: hidden;
  &:hover {
    cursor: pointer; } }

#Frontground {
  // display: none
  user-select: none;
  pointer-events: none;
  // visibility: hidden
  z-index: 2;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
  animation: spin 1000s linear infinite;
  filter: none;
  width: 60%;
  height: 60%; }

.sat {
  animation: spin 100s linear infinite; }

.copyright {
  // width: 100%
  height: 20px;
  max-width: 1024px;
  user-select: none;
  transition: opacity 200ms ease;
  position: absolute;
  z-index: 2;
  bottom: 10px;
  left: 10%;
  right: 0;
  margin: auto;
  // text-align: center
  font-size: .75em;
  opacity: .2;
  &:hover {
    opacity: 1; } }

// @media screen and (min-width: 1500px)
//   #Frontground
//     z-index: 5
//     filter: url(#blur)

@media screen and (orientation:portrait) {
  .app {
    // background: linear-gradient(325deg, red, blue, #ffffff)
    background: linear-gradient(-180deg, #ffffff, #ffffff, #ffffff, #ffffff, #ffffff, #eaebef);
    background-repeat: no-repeat;
    background-size: 100% 400%;
    animation: gradiantAnimTopBot 6s ease infinite;
    // padding: 0 20px 90px
 }    // width: calc(100vw - 60px)
  .copyright {
    color:#282d2d {}
    opacity: .1;
    top: 5px;
    left: 0;
    bottom: initial;
    &:hover {
      opacity: .4; } }

  // #Frontground
  //   filter: url(#blur)

  .flags {
    top: 6vh;
    right: 4vh;
    .modal {
      padding-top: 20%;
      vertical-align: middle;
      padding: 0;
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      justify-content: center;
      font-size: 2em;
      div {
        min-height: 50px; }
      &>* {
        vertical-align: middle; } } } }
