// #Satellite
//   visibility: hidden


#Background {
  pointer-events: none;
  // visibility: hidden
  z-index: -1;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
  animation: spin 2000s linear infinite reverse; }

#RootPlanet {
  user-select: none;
  visibility: hidden;
  position: relative;
  z-index: 0; }

// .about
//   .svg-container
//       transition: bottom 300ms ease

.svg-container {
  // transition: bottom 100ms ease
  position: fixed;
  right: 10%;
  bottom: 0;
  width: 30em;
  height: 80vh;
  width: 80vh; }

#Cil {
  &:hover {
    cursor: pointer; } }
#CilEyesClose {
  animation: flick 6s 3s ease infinite; }

#Rot {
  &:hover {
    cursor: pointer; }
  #RotRightEye circle:last-child {
    /*fill:red;*/
    animation-name: rotRightEyeAnim, blinkColor;
    animation-duration: 5s, 10s;
    animation-delay: 1s, 0;
    animation-timing-function: ease, ease;
    animation-iteration-count: infinite, infinite; }

  #RotLeftEye circle:last-child {
    animation: rotLeftEyeAnim 5s 3s ease infinite;
 }    // animation: blinkColor 2s 4s ease infinite reverse
  #RotLeftEyeFill {
    fill: #F79838;
    animation: rotLeftEyeAnimColor 5s ease infinite; } }


.shadow-char {
  visibility: hidden;
 }  // position: relative
.shadow1, .shadow2, .shadow3 {
  transform-origin: center center;
 }  // mix-blend-mode: darken
.shadow1 {
  //500
  animation: spin 500s linear infinite; }
.shadow2 {
  // 1000
  animation: spin 400s linear infinite reverse; }
.shadow3 {
  // 2000
  animation: spin 2000s linear infinite; }

@media screen and (min-width: 1024px) {
  .svg-container {
    right: 0; } }

@media screen and (orientation:portrait) {
  .svg-container {
    // transition: bottom 1ms ease
    right: -50%;
    left: -50%;
    margin: auto;
 } }    // width: 150%
